* {
  box-sizing: border-box;
  /* outline: 1px dashed #f00; */
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
}

.ui-dropdown .ui-dropdown__container {
    width: 280px;
}

.ui-dropdown .ui-list {
    width: 280px;
}

body {
  margin: 0;
  font-family: "Roboto Flex";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  max-height: 100vh;
  padding: 16px;
  background-color: #FFF6F0;
}
